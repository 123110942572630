import { useField } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import logo from '../images/100x100.jpg';
import { convertFileToBase64 } from './convert-file-to-base64';

export const UseFileUploadInput = (name: string) => {
  const [preview, setPreview] = useState<string>(logo);
  const [isMounted, setIsMounted] = useState(false);

  const [field, , { setValue }] = useField({ name });

  useEffect(() => {
    if (field.value && isMounted === false) {
      throw new Error('[UseFileUploadInput (TODO)] missing file upload');
    } else {
      setIsMounted(true);
    }
  }, [setPreview, isMounted, field.value]);

  const onDrop = useCallback(
    async ([acceptedFile]: File[]) => {
      if (acceptedFile) {
        const base64 = await convertFileToBase64(acceptedFile);
        setPreview(base64);
        setValue(acceptedFile);
      }
    },
    [setValue, setPreview]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
    maxFiles: 1,
  });

  return {
    getRootProps,
    getInputProps,
    preview,
  };
};
