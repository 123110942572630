import { toast } from 'react-toastify';

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        toast.error('[ConvertBase64:FileReader] reader result is not string');
        reject();
      }
    };
    reader.onerror = () => {
      toast.error('[ConvertBase64:FileReader] error');
      reject();
    };
  });
};
