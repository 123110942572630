import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button';
import { AuthCard } from '../components/auth/AuthCard';
import { AccountDeleteFormBody, useAccountDeleteForm } from '../forms/use-account-delete.form';
import QMCloudsLogo from '../images/qmc-logo-white.svg';
import { AppFormGenerator } from '../releox-engine/react-components/app-form-generator/AppFormGenerator';

export const AccountDeleteScene = () => {
  const form = useAccountDeleteForm();
  const { t } = useTranslation('AccountDeleteScene');
  const [loading, setLoading] = useState(false);
  const [showThanks, setShowThanks] = useState(false);

  const handleLogin = (body: AccountDeleteFormBody) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/account-deletion`, body)
      .then(() => {
        setShowThanks(true);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const FooterElement = (
    <Button loading={loading} type="submit">
      {t('button')}
    </Button>
  );

  if (showThanks) {
    return (
      <>
        <div className="mb-4">
          <img className="mx-auto h-16 w-auto" src={QMCloudsLogo} alt="QMClouds logo" />
        </div>
        <AuthCard>
          <div className="mb-5">
            <h2 className="text-xl font-bold text-gray-900">{t('ty-title')}</h2>
            <p className="mt-1 text-sm text-gray-600">{t('ty-info')}</p>
          </div>
        </AuthCard>
      </>
    );
  }

  return (
    <>
      <div className="mb-4">
        <img className="mx-auto h-16 w-auto" src={QMCloudsLogo} alt="QMClouds logo" />
      </div>
      <AuthCard>
        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-900">{t('title')}</h2>
          <p className="mt-1 text-sm text-gray-600">{t('info')}</p>
        </div>
        <AppFormGenerator
          form={form}
          onSubmit={handleLogin}
          wrapperClassNames="space-y-3"
          FooterElement={FooterElement}
        />
      </AuthCard>
    </>
  );
};
