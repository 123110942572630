/* eslint-disable react-hooks/rules-of-hooks */
import { useTranslation } from 'react-i18next';
import { UserIndexSceneQuery } from '../generated/graphql';
import { formatDate } from '../utils/format-date';
import { TableColumn } from './table-column';

type ColumnData = UserIndexSceneQuery['users']['list'][0];

const name: TableColumn = {
  field: 'name',
  sort: true,
  text: 'User:name',
};

const acl: TableColumn = {
  field: 'acl',
  text: 'User:acl',
  formatter: (data: ColumnData) => {
    const { t } = useTranslation('User');
    if (data.acl) return data.acl.name;
    return t('all');
  },
};

const groups: TableColumn = {
  field: 'groups.name',
  text: 'User:groups',
  formatter: (data: ColumnData) => {
    if (data.groups.length === 0) return '-';
    return data.groups.map((group) => group.name).join(', ');
  },
};

const isApprover: TableColumn = {
  field: 'isApprover',
  text: 'User:isApprover',
  formatter: (data: ColumnData) => {
    const { t } = useTranslation('User');
    return data.isApprover ? t('yes') : t('no');
  },
};

const createdAt: TableColumn = {
  field: 'createdAt',
  sort: true,
  text: 'User:createdAt',
  formatter: (data: ColumnData) => {
    return formatDate(data.createdAt);
  },
};

const email: TableColumn = {
  field: 'email',
  text: 'User:email',
};

export const userColumn = {
  createdAt,
  acl,
  isApprover,
  groups,
  name,
  email,
};
