import { useTranslation } from 'react-i18next';
import { useReportInitialValues } from '../forms/use-report.form';
import { useHomeSceneReportQuery } from '../generated/graphql';
import { Card } from './Card';
import { CardTitle } from './CardTitle';
import { ReportQncCostChart } from './report/ReportQncCostChart';
import { ReportQncCountChart } from './report/ReportQncCountChart';
import { ReportQncTypeChart } from './report/ReportQncTypeChart';
import { Tab } from './tab/Tab';

export const HomeSceneReport = () => {
  const initialValues = useReportInitialValues();
  const { t } = useTranslation('HomeScene');

  const { data, loading } = useHomeSceneReportQuery({
    variables: {
      start: initialValues.start,
      end: initialValues.end,
    },
  });

  return (
    <div className="xl:col-span-3 space-y-5">
      <Card>
        <Tab
          tabs={[
            {
              name: t('ReportQncCountScene:title'),
              children: (
                <div className="mb-2">
                  <CardTitle>{t('ReportQncCountScene:title')}</CardTitle>
                  <ReportQncCountChart data={data?.qncCountReport} loading={loading} hideCount />
                </div>
              ),
            },
            {
              name: t('ReportQncTypeScene:title'),
              children: (
                <div className="mb-2 hidden sm:block">
                  <CardTitle>{t('ReportQncTypeScene:title')}</CardTitle>
                  <ReportQncTypeChart data={data?.qncCategoryReport} loading={loading} hideCount />
                </div>
              ),
            },
            {
              name: t('ReportQncCostScene:title'),
              children: (
                <div className="mb-2">
                  <CardTitle>{t('ReportQncCostScene:title')}</CardTitle>
                  <ReportQncCostChart data={data?.qncCostsReport} loading={loading} hideCount />
                </div>
              ),
            },
          ]}
        />
      </Card>
    </div>
  );
};
