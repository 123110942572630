import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const msRegex = /src="(https:\/\/[a-zA-Z-]*.sharepoint.com\/[a-zA-Z0-9/_.?&=;{}-]*)"/;

export const useMSLinkInput = () => {
  const Yup = useYup();

  const iframe: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().matches(msRegex).required(),
    props: {
      name: 'iframe',
      label: 'MSLink:iframe',
      type: 'text',
    },
  };

  return {
    iframe,
  };
};
