import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useApolloClient } from './apollo-client';
import AppRoutes from './AppRoutes';

const ApolloClientProvider: React.FC = () => {
  const client = useApolloClient();

  return (
    <ApolloProvider client={client}>
      <AppRoutes />
      <ToastContainer />
    </ApolloProvider>
  );
};

export default ApolloClientProvider;
