import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';

type Props = {
  onClick: () => void;
  loading: boolean;
  tNS?: string;
};

export const DeleteButton = ({ onClick, loading }: Props) => {
  const { t } = useTranslation('Common');
  return (
    <>
      <hr />
      <Button
        type="button"
        onClick={onClick}
        loading={loading}
        className="float-left bg-tertiary hover:bg-red-500"
      >
        {t('deleteText')}
      </Button>
      <div className="clear-both" />
    </>
  );
};
