import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Card = ({ children, className }: Props) => (
  <div className={`bg-white p-4 shadow rounded-lg ${className}`}>{children}</div>
);
