import { Form, Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BackgroundClass } from '../../components/BackgroundClass';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { AuthError } from '../../components/auth/AuthError';
import { getRoute } from '../../config/routes.config';
import {
  DocumentCreateForm,
  DocumentCreateInitialValues,
  useDocumentCreateInitialValues,
  useDocumentCreateValidationSchema,
} from '../../forms/use-document-create.form';
import {
  PublicDocumentCreateMutation,
  useDocumentModelPublicityQuery,
  usePublicDocumentCreateMutation,
} from '../../generated/graphql';
import { useDocumentTitle } from '../../hooks/use-document-title';
import QMCloudsLogo from '../../images/qmc-logo-white.svg';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import {
  remapDocumentFormFieldToDocumentField,
  remapModelFieldToEmptyDocumentFormField,
} from '../../utils/document-remap-helpers';

export const PublicDocumentCreate = () => {
  const schema = useDocumentCreateValidationSchema();
  const values = useDocumentCreateInitialValues();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPublic, setPublicity] = useState(false);
  const [initValues, setInitialValues] = useState<DocumentCreateInitialValues | null>(null);
  const { t, i18n } = useTranslation('PublicDocumentCreate');

  const onCompleted = useOnComplete(null, 'saved', (data: PublicDocumentCreateMutation) => {
    if (data.createPublicDocument) {
      navigate(getRoute('PUBLIC_THANKS', { id }));
    }
  });

  // Throw error if id is undefined
  if (!id) {
    throw new Error('id is undefined');
  }

  const { data, loading } = useDocumentModelPublicityQuery({
    variables: { id: id },
  });

  const [create, { loading: isCreateLoading }] = usePublicDocumentCreateMutation({
    onCompleted: onCompleted,
  });

  const handleSubmit = async (
    body: DocumentCreateInitialValues,
    formikHelpers: FormikHelpers<DocumentCreateInitialValues>
  ) => {
    // Throw error if model is undefined
    if (!data?.documentModelPrivilege.model) {
      throw new Error('Model is invalid');
    }

    await create({
      variables: {
        body: {
          ...body,
          fields: _.map(body.fields, remapDocumentFormFieldToDocumentField),
          type: data?.documentModelPrivilege.model?.type,
          documentModelId: data?.documentModelPrivilege.model.id,
          groups: [],
          tags: [],
        },
      },
    });

    formikHelpers.resetForm();
  };

  useEffect(() => {
    if (data?.documentModelPrivilege.privilege === 'public') {
      setPublicity(true);
    }
  }, [data?.documentModelPrivilege]);

  useEffect(() => {
    const fields = _.map(
      data?.documentModelPrivilege.model?.documentModelFields,
      remapModelFieldToEmptyDocumentFormField
    );

    setInitialValues({
      ...values,
      ...(data?.documentModelPrivilege.model?.type === 'qnc' ? { costs: 0 } : {}),
      isApproveRequired: false,
      fields,
    });
  }, [data?.documentModelPrivilege.model, values]);

  const title = useDocumentTitle({ type: data?.documentModelPrivilege.model?.type, t });

  const changeLanguage = (language: string) => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  };

  if (loading || !initValues) {
    return <Loading />;
  }

  if (!isPublic) {
    return <AuthError errorReason={t('isPrivate')} loginRoute={getRoute('LOGIN')} />;
  }

  return (
    <div className="pt-6">
      <BackgroundClass className="BlueBackground" />

      <div className="py-10">
        <img className="mx-auto h-16 w-auto" src={QMCloudsLogo} alt="QMClouds logo" />
      </div>
      <FormLayout key={JSON.stringify(initValues)}>
        <Card>
          <div className="float-right space-x-3">
            <button type="button" onClick={() => changeLanguage('fi')}>
              Finnish
            </button>
            <span>/</span>
            <button type="button" onClick={() => changeLanguage('en')}>
              English
            </button>
          </div>
          <CardTitle>{title}</CardTitle>
          <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initValues}>
            <Form>
              <DocumentCreateForm type={data?.documentModelPrivilege.model?.type || ''} />
              <Button type="submit" className="float-right" loading={isCreateLoading}>
                {t('Common:save')}
              </Button>
              {/* Clear both */}
              <div style={{ clear: 'both' }} />
            </Form>
          </Formik>
        </Card>
      </FormLayout>
    </div>
  );
};
