import { Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { InlineSelectInput } from '../../components/InlineSelectInput';
import { SelectInput } from '../../components/inputs/select-input';
import { TextInput } from '../../components/inputs/text-input';
import { Loading } from '../../components/Loading';
import { ReactMultiSelectInput } from '../../components/ReactMultiSelectInput';
import { ReactSelectInput } from '../../components/ReactSelectInput';
import {
  getDocCreateMiroRoute,
  getDocCreateMSRoute,
  getDocIndexRoute,
  getDocShowRoute,
  getRoute,
} from '../../config/routes.config';
import {
  useDeleteDocumentMutation,
  useDocumentCreateSceneQuery,
  useRiskEditFormQuery,
  useUpdateRiskMutation,
} from '../../generated/graphql';
import { useParamId } from '../../hooks/use-id';
import { useUserHavePermission } from '../../hooks/use-user-have-permission';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { CheckboxInput } from '../../releox-engine/react-components/form-inputs/Checkbox';
import { isI18nString } from '../../utils/is-i18n-string';
import { useSchemaFromForm } from './use-schema-from-form';

export const RiskEditScene = (): JSX.Element => {
  const id = useParamId();
  const { t } = useTranslation('DocumentEditScene');
  const navigate = useNavigate();
  const haveReadPrivilege = useUserHavePermission(`risk.read`);
  const haveDeletePrivilege = useUserHavePermission(`risk.delete`);

  const { data: _D } = useRiskEditFormQuery({
    variables: { id },
  });

  const formSchema = useSchemaFromForm('edit', _D?.riskEditForm.fields || []);

  const INDEX = getDocIndexRoute('risk');
  const SHOW = getDocShowRoute('risk');
  const MIRO = getDocCreateMiroRoute('risk');
  const MS = getDocCreateMSRoute('risk');

  const [deleteDocument, { loading: isDeleteLoading }] = useDeleteDocumentMutation({
    variables: { id },
    onCompleted: (d) => {
      if (d?.deleteDocument) {
        toast.success(t('Common:deleted'));
        navigate(getRoute(INDEX));
      }
    },
  });

  const onCompleted = useOnComplete(SHOW);

  const { data, loading: isQueryLoading } = useDocumentCreateSceneQuery({
    variables: { type: 'risk' },
  });

  const [createRisk, { loading }] = useUpdateRiskMutation({
    onCompleted,
  });

  const handleSubmit = useCallback(
    (body: Record<string, string>) => {
      createRisk({
        variables: {
          id,
          body: JSON.stringify(body),
        },
      });
    },
    [createRisk, id]
  );

  const handleDelete = useCallback(() => {
    if (confirm(t('confirm'))) deleteDocument();
  }, [deleteDocument, t]);

  if (isQueryLoading || !data || !_D) return <Loading />;

  const initialValues = JSON.parse(_D.riskEditForm.initialValues);

  return (
    <FormLayout key={_D.riskEditForm.initialValues}>
      <Card>
        <CardTitle>{t(`riskTitle`)}</CardTitle>
        <Formik validationSchema={formSchema} onSubmit={handleSubmit} initialValues={initialValues}>
          {({ submitForm, values }) => (
            <div className="space-y-4">
              {_D.riskEditForm.fields.map((field) => {
                let label = field.label;

                if (isI18nString(field.label)) {
                  label = t(field.label);
                }

                if (field.type === 'text') {
                  return <TextInput name={field.name} type="text" label={label} key={field.name} />;
                }

                if (field.type === 'date') {
                  return <TextInput type="date" key={field.name} name={field.name} label={label} />;
                }

                if (field.type === 'number') {
                  return (
                    <TextInput type="number" key={field.name} name={field.name} label={label} />
                  );
                }

                if (field.type === 'select') {
                  return (
                    <SelectInput
                      name={field.name}
                      options={field.options}
                      label={label}
                      key={field.name}
                    />
                  );
                }

                if (field.type === 'react-select') {
                  if (field.name === 'approverId' && !values.isApproveRequired) {
                    return <span key={field.name} />;
                  }
                  return (
                    <ReactSelectInput
                      options={field.options}
                      placeholder={label}
                      label={label}
                      name={field.name}
                      key={field.name}
                    />
                  );
                }

                if (field.type === 'multi-select') {
                  return (
                    <ReactMultiSelectInput
                      options={field.options}
                      placeholder={label}
                      label={label}
                      name={field.name}
                      key={field.name}
                    />
                  );
                }

                if (field.type === 'checkbox') {
                  return <CheckboxInput name={field.name} label={label} key={field.name} />;
                }

                if (field.type === 'inline-select') {
                  return (
                    <InlineSelectInput
                      key={field.name}
                      name={field.name}
                      options={field.options}
                      label={label}
                    />
                  );
                }

                return (
                  <div key={field.name}>
                    {field.name} - {field.type}
                  </div>
                );
              })}

              <div className="mb-3">
                <Link
                  className="text-primary hover:text-secondary text-sm"
                  to={getRoute(MIRO, { id })}
                >
                  {t('createMiroLink')}
                </Link>
              </div>
              <div className="mb-3">
                <Link
                  className="text-primary hover:text-secondary text-sm"
                  to={getRoute(MS, { id })}
                >
                  {t('createWordLink')}
                </Link>
              </div>

              <div className="mb-4">
                <Button
                  type="submit"
                  className="float-right"
                  loading={loading || isDeleteLoading}
                  onClick={submitForm}
                >
                  {t('Common:save')}
                </Button>
                <ButtonLink to={haveReadPrivilege ? getRoute(INDEX) : getRoute('DASHBOARD')}>
                  {t('Common:back')}
                </ButtonLink>
              </div>
              {haveDeletePrivilege && (
                <>
                  <hr />
                  <Button
                    className="mt-4"
                    type="button"
                    onClick={handleDelete}
                    loading={loading || isDeleteLoading}
                  >
                    {t('Common:delete')}
                  </Button>
                </>
              )}
            </div>
          )}
        </Formik>
      </Card>
    </FormLayout>
  );
};
