import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { getCreateLinkRoute, getEditLinkRoute, getRoute } from '../../config/routes.config';
import { DocumentShowSceneQuery } from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import { ButtonLink } from '../ButtonLink';
import { Card } from '../Card';
import { CardTitle } from '../CardTitle';
import { CogIcon } from '../icons/CogIcon';
import { TrashIcon } from '../icons/TrashIcon';
import { SmallTable } from '../small-table/SmallTable';
import { SmallTableHeader } from '../small-table/SmallTableHeader';

type Props = {
  data: DocumentShowSceneQuery;
  type: DocumentTypeField;
  hasCreateOrEditPrivilege: boolean;
  handleDelete(id: string): void;
};

export const DSDocumentLinks: FC<Props> = ({
  data,
  type,
  hasCreateOrEditPrivilege,
  handleDelete,
}) => {
  const { t } = useTranslation('DocumentShowScene');
  const { id } = useParams();
  const ADD_LINK_KEY = getCreateLinkRoute(type);
  const EDIT_LINK_KEY = getEditLinkRoute(type);

  return (
    <Card className="mt-3">
      <div className="flex justify-between">
        <CardTitle>{t('links')}</CardTitle>
        {hasCreateOrEditPrivilege && (
          <div>
            <ButtonLink to={getRoute(ADD_LINK_KEY, { id })}>{t('addLink')}</ButtonLink>
          </div>
        )}
      </div>
      <SmallTable>
        <SmallTableHeader fields={[t('name'), t('actions')]} />
        <tbody>
          {data.document.documentLinks.map((link) => (
            <tr key={link.id}>
              <td>
                <a
                  target="_blank"
                  className="text-primary underline"
                  href={link.url}
                  rel="noreferrer"
                >
                  {link.name}
                </a>
              </td>
              <td>
                <div className="flex">
                  {hasCreateOrEditPrivilege && (
                    <button onClick={() => handleDelete(link.id)}>
                      <TrashIcon />
                    </button>
                  )}
                  {hasCreateOrEditPrivilege && (
                    <Link to={getRoute(EDIT_LINK_KEY, { id, linkId: link.id })}>
                      <CogIcon />
                    </Link>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </SmallTable>
      {data.document.documentLinks.length ? null : (
        <p className="text-center text-gray-600">{t('emptyLinks')}</p>
      )}
    </Card>
  );
};
