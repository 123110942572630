export default {
  fi: {
    Pagination: {
      page: 'Sivu',
      result: 'Tulos',
      next: 'Seuraava',
      previous: 'Edellinen',
    },
  },
  en: {
    Pagination: {
      page: 'Page',
      result: 'Result',
      next: 'Next',
      previous: 'Previous',
    },
  },
};
