import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { getRoute } from '../../../config/routes.config';

export const ResetPasswordSuccessScene = () => {
  const { t } = useTranslation('ResetPasswordSuccessScene');

  return (
    <AuthCard>
      <div className="mb-5">
        <h2 className="text-xl font-bold text-gray-900">{t('passwordChanged')}</h2>
        <p className="mt-1 text-sm text-gray-600">{t('successfullyChanged')}</p>
      </div>
      <Link to={getRoute('LOGIN')} className="text-primary hover:text-secondary block text-sm ">
        {t('backToLogin')}
      </Link>
    </AuthCard>
  );
};
