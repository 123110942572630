export default {
  fi: {
    ACL: {
      name: 'Nimi',
      rules: 'Säännöt',
      model: 'Dokumenttipohja',
      modelPlaceholder: 'Valitse dokumenttipohja',
      rulesPlaceholder: 'Valitse säännöt',
      actions: 'Toiminnot',
      permissions: 'Oikeudet',
      users: 'Käyttäjät',
      userCount: 'Käyttäjien määrä',
      createdAt: 'Luotu',
    },
  },
  en: {
    ACL: {
      name: 'Name',
      rules: 'Rules',
      model: 'Document Model',
      modelPlaceholder: 'Select document model',
      rulesPlaceholder: 'Select rules',
      actions: 'Actions',
      permissions: 'Permissions',
      users: 'Users',
      userCount: 'User count',
      createdAt: 'Created',
    },
  },
};
