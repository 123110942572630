import { CheckIcon } from '@heroicons/react/outline';
import moment from 'moment';
import 'moment/locale/fi';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexes/UserContext';
import { DocumentShowSceneQuery } from '../generated/graphql';
import { joinClassNames } from '../utils/join-class-names';
import { CardTitle } from './CardTitle';

const defaultOptions = {
  date: 'Oct 4',
  datetime: '2020-10-04',
  icon: CheckIcon,
};

type Props = {
  history: DocumentShowSceneQuery['logs'];
};

const negativeEvents = ['rejected', 'file-delete'];

export const SimpleHistory = ({ history }: Props) => {
  const { t } = useTranslation('LogEvent');
  const [user] = useContext(UserContext);

  moment.locale(user.language);

  if (!history.length) return <span />;

  return (
    <div className="">
      <CardTitle>{t('title')}</CardTitle>
      <div className="flow-root -mt-2">
        <ul className="space-y-5">
          {history.map((event, eventIdx: number) => (
            <li key={event.id}>
              <div className="relative">
                {eventIdx !== history.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={joinClassNames(
                        negativeEvents.includes(event.type) ? 'bg-tertiary' : 'bg-primary',
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white mt-1'
                      )}
                    >
                      <defaultOptions.icon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-900">
                        {event.message || t(`LogEvent:${event.type}`)}
                        <br />
                        <span className="text-gray-500">
                          <span className="font-medium">{event.user?.name || '-'}</span>
                          {', '}
                          <time dateTime={event.createdAt}>
                            {moment(event.createdAt).format('D.M.YYYY HH:mm')}
                          </time>
                        </span>
                      </p>
                    </div>
                    {/* <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    <time dateTime={event.doneDate}>{moment(event.doneDate).fromNow()}</time>
                  </div> */}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
