import moment from 'moment';
import { FC, useContext, useState } from 'react';
import UserContext from '../../contexes/UserContext';
import { usePaperipankkiRequestMutation } from '../../generated/graphql';
import Assistant from '../../images/assistant.png';
import PaperipankkiLogo from '../../images/paperipankki-logo-n.png';
import Toni from '../../images/toni-stand.png';

const getKey = () => {
  const date = moment().format('YYYY-MM-DD');
  return `paperipankki-${date}`;
};

const REQUESTED_TODAY = '1';

const checkIfRequested = () => {
  const bool = localStorage.getItem(getKey());

  if (bool === REQUESTED_TODAY) {
    return true;
  }

  return false;
};

export const PaperipankkiScene = () => {
  const [isRequested, setIsRequested] = useState(checkIfRequested());
  const [user] = useContext(UserContext);

  const [requestContact] = usePaperipankkiRequestMutation({
    onCompleted: (d) => {
      if (d.requestPaperipankkiContact) {
        localStorage.setItem(getKey(), REQUESTED_TODAY);
        setIsRequested(true);
      }
    },
  });
  const request = () => {
    requestContact({
      variables: {
        body: {
          cta: '[QM] Paperipankki contact',
          section: 'Paperipankki Scene',
          source: window.location.href,
        },
      },
    });
  };

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="">
        <div className="hero-img">
          <div className="my-auto p-8 md:p-16 lg:p-24">
            <div className="inline-block">
              <img src={PaperipankkiLogo} alt="Logo" className="h-8" />
              <h1 className="text-paperipankki">
                <span className="block text-4xl font-bold uppercase lg:text-8xl">Paperipankki</span>
                <span className="block max-w-xl mb-4 text-xl font-bold uppercase lg:text-4xl">
                  Helppokäyttöinen dokumentointi työkalu
                </span>
              </h1>
              <div className="max-w-xl">
                <p className="mt-2 text-xl tracking-tight text-paperipankki lg:text-xl">
                  Paperipankki on digitaalinen ratkaisu, joka tarjoaa yrityksille helpon ja
                  turvallisen tavan hallita, säilyttää ja jakaa tärkeitä dokumentteja. Kaikki
                  asiakirjat pysyvät järjestyksessä ja ovat nopeasti saatavilla paikasta
                  riippumatta.
                </p>
                <div className="flex mt-6">
                  <Foo
                    isAdmin={user.isCompanyAdmin || user.isCompanyOwner}
                    onClick={request}
                    isRequested={isRequested}
                    cls="inline-flex items-center justify-center px-4 py-2 text-base font-medium bg-white border border-transparent rounded-md shadow-sm whitespace-nowrap text-paperipankki hover:bg-paperipankki hover:text-white"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-8 md:p-16 lg:p-24">
        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div className="lg:pr-12 my-auto">
            <p className="text-xl tracking-tight">
              Muuta fyysiset lomakkeet digitaaliseksi arkistoksi
            </p>
            <h2 className="text-3xl font-bold text-paperipankki pt-2 pb-6">
              Digitalisoi yrityksesi paperiprosessit
            </h2>
            <p className="tracking-tight text-lg">
              Paperipankilla voit vaivattomasti siirtää yrityksesi lomakkeet sähköiseen muotoon,
              mikä parantaa tehokkuutta, säästää tilaa ja nopeuttaa tiedon käsittelyä. Paperiset
              asiakirjat voi skannata ja tallentaa järjestelmään, josta ne ovat aina helposti ja
              turvallisesti löydettävissä. Aloita siirtymä digimaailmaan jo tänään ja hyödy
              nykyaikaisen teknologian eduista!
            </p>
            <div className="space-y-2 mt-6 tracking-tight">
              <p>
                <strong className="text-paperipankki">Tehokkuus:</strong> Sähköinen käsittely
                vähentää manuaalista työtä ja nopeuttaa dokumenttien hakua.
              </p>
              <p>
                <strong className="text-paperipankki">Tilansäästö:</strong> Vähennä paperiarkistojen
                tarvetta ja vapauta tilaa.
              </p>
              <p>
                <strong className="text-paperipankki">Helppo saatavuus:</strong> Löydä asiakirjat
                hetkessä digitaalisen arkiston kautta.
              </p>
              <p>
                <strong className="text-paperipankki">Turvallisuus:</strong> Digitaalinen tallennus
                suojaa asiakirjat ja mahdollistaa varmuuskopioinnin.
              </p>
              <p>
                <strong className="text-paperipankki">Ympäristöystävällisyys:</strong> Vähennä
                paperin kulutusta ja pienennä ympäristöjalanjälkeä.
              </p>
            </div>
            <h3 className="text-xl font-bold mt-6 pb-3 text-paperipankki tracking-tight">
              Intuitiivinen käyttöliittymä, helppo omaksua
            </h3>
            <p className="tracking-tight text-lg">
              Paperipankin selkeä ja käyttäjäystävällinen käyttöliittymä mahdollistaa nopean
              aloituksen ilman monimutkaista opettelua. Jokainen toiminto on suunniteltu
              helpottamaan päivittäistä käyttöä, mikä parantaa tuottavuutta ja vähentää tarpeetonta
              stressiä.
            </p>
          </div>
          <div className="mt-6 xl:my-auto">
            <img
              slot="right"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 my-auto hidden lg:block"
              src={Assistant}
              alt="Henkilö katsomassa tietokoneen näyttöä ja aloittamassa paperipankin käytön"
            />
          </div>
        </div>

        <div className="grid max-w-2xl grid-cols-1 mx-auto gap-y-16 gap-x-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 mt-12 lg:mt-24">
          <div>
            <img src={Toni} alt="Myyjä seisomassa" className="m-auto h-80" />
          </div>
          <div className="col-span-2 lg:mt-12">
            <p className="text-xl tracking-tight">
              Ota rohkeasti yhteyttä, niin varataan teille aika ja pistetään dokumentointi kuntoon
            </p>
            <h2 className="text-3xl font-bold mt-3 text-paperipankki mb-6">
              Aloita Paperipankin käyttö
            </h2>
            <p className="tracking-tight text-lg">
              Paperipankki on digitaalinen alusta, joka mahdollistaa asiakirjojen turvallisen
              säilyttämisen, jakamisen ja hallinnan. Se tarjoaa käyttäjille kätevän tavan pitää
              tärkeät dokumentit järjestyksessä ja helposti saatavilla missä tahansa.
            </p>
            <div className="mt-4 tracking-tight text-lg">
              Soita {''}
              <a href="tel:+358505172765" className="font-bold text-paperipankki">
                050 517 2765
              </a>
              {''} tai
              <br />
              <Foo
                isAdmin={user.isCompanyAdmin || user.isCompanyOwner}
                onClick={request}
                isRequested={isRequested}
                cls="mt-4 inline-flex items-center justify-center px-4 py-2 text-base font-medium bg-paperipankki border border-transparent rounded-md shadow-sm whitespace-nowrap text-white hover:bg-white hover:text-paperipankki hover:border-paperipankki"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  isAdmin: boolean;
  isRequested: boolean;
  onClick: () => void;
  cls: string;
};

const Foo: FC<Props> = ({ isAdmin, onClick, isRequested }) => {
  if (isRequested) {
    return <p>Kiitos yhteydenotostasi! Olemme ottaneet sinuun yhteyttä mahdollisimman pian.</p>;
  }

  if (isAdmin) {
    return (
      <button
        onClick={onClick}
        type="button"
        className="mt-4 inline-flex items-center justify-center px-4 py-2 text-base font-medium bg-paperipankki border border-transparent rounded-md shadow-sm whitespace-nowrap text-white hover:bg-white hover:text-paperipankki hover:border-paperipankki"
      >
        Ota yhteyttä
      </button>
    );
  }

  return (
    <a
      href="https://paperipankki.fi/yhteystiedot"
      target="_blank"
      rel="noreferrer"
      className="mt-4 inline-flex items-center justify-center px-4 py-2 text-base font-medium bg-paperipankki border border-transparent rounded-md shadow-sm whitespace-nowrap text-white hover:bg-white hover:text-paperipankki hover:border-paperipankki"
    >
      Tutustu
    </a>
  );
};
