import Axios from 'axios';
import moment from 'moment';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Loading } from '../../components/Loading';
import { authConfig } from '../../config/auth.config';
import UserContext from '../../contexes/UserContext';
import { useCustomDocumentModelsQuery, useExportSceneQuery } from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import { saveStringAsFile } from '../../utils/save-string-as-file';

export const ExportIndexScene = () => {
  const { t } = useTranslation('ExportIndexScene');
  const [disable, setDisable] = useState(false);
  const [user] = useContext(UserContext);
  const { data: documentCount, loading } = useExportSceneQuery();
  const { data: models } = useCustomDocumentModelsQuery({
    variables: {
      modelIds: user.myCustomDocuments.read,
    },
  });

  const download = useCallback(async (type: DocumentTypeField) => {
    try {
      setDisable(true);
      const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}/export-system/${type}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(authConfig.tokenStorageKey)}` },
      });

      saveStringAsFile(`qmclouds-${type}s-${moment().format('YYYY-MM-DD')}.csv`, data);
      setDisable(false);
    } catch (e) {
      setDisable(false);
    }
  }, []);

  if (!documentCount || loading) return <Loading />;

  if (documentCount?.documentCount === 0)
    return (
      <Card>
        <CardTitle>{t('empty')}</CardTitle>
      </Card>
    );

  return (
    <Card className="mx-left w-full max-w-3xl">
      <CardTitle>{t('title')}</CardTitle>
      <div className="grid grid-cols-1 space-y-4">
        <Button loading={disable} onClick={() => download('document')} type="button">
          {t('documents')}
        </Button>
        <Button loading={disable} onClick={() => download('qnc')} type="button">
          {t('qncs')}
        </Button>
        <Button loading={disable} onClick={() => download('risk')} type="button">
          {t('risks')}
        </Button>
        {user.isAnonymousHandler ? (
          <Button loading={disable} onClick={() => download('anonymous')} type="button">
            {t('anonymous')}
          </Button>
        ) : null}
        {models?.customDocumentModels.map((model) => (
          <Button
            key={model.id}
            loading={disable}
            onClick={() => download(model.type as DocumentTypeField)}
            type="button"
          >
            {model.type}
          </Button>
        ))}
      </div>
    </Card>
  );
};
