export default {
  fi: {
    SideBar: {
      home: 'Etusivu',
      settings: 'Asetukset',
      logOut: 'Kirjaudu ulos',
      userEdit: 'Käyttäjän asetukset',
      companyEdit: 'Yrityksen asetukset',
      groupIndex: 'Ryhmät',
      documentModelIndex: 'Kenttien asetukset',
      tagIndex: 'Tagit',
      documents: 'Dokumentit',
      qncs: 'Poikkeamat',
      risks: 'Riskit ja mahdollisuudet',
      reports: 'Raportit',
      teamEdit: 'Tiimin asetukset',
      exports: 'Vie tietoja',
      anonymous: 'Anonyymi ilmoituskanava',
      customDocuments: 'Lomakkeet',
      acl: 'Käyttöoikeudet',
      paperipankki: 'Paperipankki',
    },
  },
  en: {
    SideBar: {
      home: 'Home',
      paperipankki: 'Paperipankki',
      settings: 'Settings',
      logOut: 'Logout',
      userEdit: 'Profile',
      companyEdit: 'Company',
      groupIndex: 'Groups',
      documentModelIndex: 'Document models',
      risks: 'Risks',
      qncs: 'QNCs',
      documents: 'Documents',
      tagIndex: 'Tags',
      reports: 'Reports',
      teamEdit: 'Team',
      exports: 'Export',
      anonymous: 'Anonymous channel',
      customDocuments: 'Custom documents',
      acl: 'Access control',
    },
  },
};
