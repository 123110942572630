import { ErrorMessage, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../releox-engine/form/ReleoxBaseInputProps';
import { InputWrapper } from '../releox-engine/react-components/form-inputs/InputWrapper';
import { Label } from '../releox-engine/react-components/form-inputs/Label';

export interface InlineSelectInputProps extends ReleoxBaseInputProps {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
}

export const InlineSelectInput = (props: InlineSelectInputProps) => {
  const { t, i18n } = useTranslation();
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  return (
    <div>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <div className="space-x-3">
          {/* Map optins to buttons */}
          {props.options.map((opt) => {
            // If the option is the current value, add className "bg-secondary"
            const className = opt.value === field.value ? 'bg-tertiary' : 'bg-secondary';

            return (
              <button
                key={opt.value}
                type="button"
                className={`inline-block px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out ${className} border border-transparent rounded-md `}
                onClick={() => {
                  field.onChange({ target: { name: field.name, value: opt.value } });
                }}
              >
                {i18n.exists(opt.label) ? t(opt.label) : opt.label}
              </button>
            );
          })}
        </div>

        <ErrorMessage component="span" className="text-red-color text-sm" name={field.name} />
      </InputWrapper>
    </div>
  );
};

export interface GeneratableTextareaInputOptions {
  type: 'inline-select';
  initValue: string;
  validation: Yup.StringSchema;
  props: InlineSelectInputProps;
}

export const GeneratableTextareaInput = {
  Element: InlineSelectInput,
  type: 'inline-select',
};
