export default {
  fi: {
    Common: {
      loading: 'Lataa...',
      back: 'Takaisin',
      save: 'Tallenna',
      deleteText: 'Poista',
      delete: 'Poista',
      updated: 'Tallennettu',
      addNew: 'Lisää uusi',
      deleted: 'Poistettu',
      submit: 'Hae',
      totalCount: 'Poikkeamat yhteensä:',
      saved: 'Tallennettu',
      confirmDelete: 'Haluatko varmasti poistaa tiedoston?',
    },
  },
  en: {
    Common: {
      loading: 'Loading...',
      back: 'Back',
      save: 'Save',
      delete: 'Delete',
      updated: 'Saved',
      addNew: 'Add new',
      deleted: 'Deleted',
      submit: 'Submit',
      totalCount: 'QNC total: ',
      saved: 'Saved',
      confirmDelete: 'Are you sure you want to delete the file?',
      deleteText: 'Delete',
    },
  },
};
